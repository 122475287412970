/**
 * Loads all third-party scripts conditionally.
 */
export function loadThirdPartyScripts() {
  // Ensure this runs only in the browser
  if (typeof window === 'undefined') return;

  // === Bing Pixel ===
  (function (w, d, t, r, u) {
    var f, n, i;
    (w[u] = w[u] || []),
      (f = function () {
        var o = { ti: '4021041' };
        (o.q = w[u]), (w[u] = new UET(o)), w[u].push('pageLoad');
      }),
      (n = d.createElement(t)),
      (n.src = r),
      (n.defer = 1),
      (n.onload = n.onreadystatechange =
        function () {
          var s = this.readyState;
          (s && s !== 'loaded' && s !== 'complete') ||
            (f(), (n.onload = n.onreadystatechange = null));
        }),
      (i = d.getElementsByTagName(t)[0]),
      i.parentNode.insertBefore(n, i);
  })(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');

  // === Google Tag Manager ===
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.defer = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-WVNG9WC');

  // === Google Tag Manager NoScript ===
  (function () {
    var bodyTag = document.getElementsByTagName('body')[0];
    var noScript = document.createElement('noscript');
    var gtmIframe = document.createElement('iframe');
    gtmIframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-WVNG9WC';
    gtmIframe.height = '0';
    gtmIframe.width = '0';
    gtmIframe.style = 'display:none;visibility:hidden';
    noScript.appendChild(gtmIframe);
    bodyTag.appendChild(noScript);
  })();

  // === Google Analytics Consent ===
  window.dataLayer = window.dataLayer || [];
  /**
   *
   */
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('consent', 'update', {
    ad_storage: 'granted',
    analytics_storage: 'granted'
  });

  // === GRIN Pixel Code ===
  (function () {
    window.Grin = window.Grin || [];
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.defer = true;
    s.src = 'https://d38xvr37kwwhcm.cloudfront.net/js/grin-sdk.js';
    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  })();
}
