<script context="module">
	import {
		initializeCookiesStore,
		setMeta,
		startLoading,
		stopLoading,
		setUserLocale
	} from '$lib/stores/app/actions';
	import Cookies from 'js-cookie';
	import { app } from '$lib/stores/app/store';

	// TODO: find a better alternative for
	// environment detection
	if (typeof window !== 'undefined') {
		setMeta(Cookies.get());
	}
</script>

<script>
	import '../app.css';
	import { browser } from '$app/environment';
	import { afterNavigate, beforeNavigate } from '$app/navigation';
	import { Modals, closeAllModals, closeModal, openModal } from 'svelte-modals';
	import Loading from '$lib/components/ComponentLoading.svelte';
	import Authorizer from '$lib/services/auth';
	import { AnalyticsService } from '$lib/services/analytics';
	import { onMount, onDestroy } from 'svelte';
	import * as Sentry from '@sentry/browser';
	import { loadThirdPartyScripts } from '$lib/utils/third-party.js';
	import { setHotjarPlatformTags } from '$lib/utils/hotjar';

	beforeNavigate(async (nav) => {
		if (nav?.to) {
			const policies = import.meta.glob('./**/-policy.*');
			const nextPage = nav?.to?.route?.id;
			const isEligiblePage = !['/', '/prospect-register', '/subscription'].includes(nextPage ?? '');
			if (nextPage && isEligiblePage) {
				startLoading({ darkMode: true, timerMode: true });
				const authorizer = new Authorizer({ modules: policies, routeId: nextPage });
				await authorizer.browserAuthorize();
			}
			stopLoading();
		}
	});

	afterNavigate(async (nav) => {
		const prevPage = nav?.from?.route?.id;
		const nextPage = nav?.to?.route?.id;

		Sentry.addBreadcrumb({
			category: 'navigation',
			message: `Navigated from ${prevPage} to ${nextPage}`,
			level: 'info'
		});

		const isEligiblePage = !['/', '/prospect-register', '/subscription'].includes(nextPage ?? '');
		// No previous page implies a full-page reload.
		// https://kit.svelte.dev/docs/modules#$app-navigation
		if (!prevPage && nextPage && isEligiblePage) {
			startLoading({ darkMode: true, timerMode: true });
			const policies = import.meta.glob('./**/-policy.*');
			const authorizer = new Authorizer({ modules: policies, routeId: nextPage });
			await authorizer.browserAuthorize();
		}
		stopLoading();
		AnalyticsService.afterNavigate();
	});

	onMount(async () => {
		if ($app?.meta?.isAmazonApp || $app?.meta?.isAndroidApp)
			document.documentElement.classList.add('fixed-font');

		initializeCookiesStore();
		AnalyticsService.onMount();
		setHotjarPlatformTags($app?.meta);

		window.addEventListener('pageshow', (e) => {
			if (e.persisted) closeAllModals();
		});

		const shouldAddAnalyticsScripts =
			browser &&
			!window?.navigator?.globalPrivacyControl &&
			!$app?.meta?.inApp &&
			$app?.global?.analyticsCookiesEnabled &&
			$app?.global?.targetingCookiesEnabled;

		if (shouldAddAnalyticsScripts) {
			loadThirdPartyScripts();
		}
		// TODO: move this to actions
		setUserLocale($app?.meta?.localeCode, $app?.meta?.viewerCountry);
	});

	onDestroy(() => {
		if (browser) AnalyticsService.onDestroy();
	});

	// TODO: consider if this is an appropriate place to add modals.
	$: if ($app?.global?.loading) {
		openModal(Loading);
	} else {
		closeModal();
	}
</script>

<!-- TODO: move this to be config driven: debug build -->
<svelte:head>
	<script
		crossorigin
		src="https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js"
		defer
	></script>
	<script></script>
	<!-- <script src="https://seo-games-abc-mouse.ngrok.dev/vorlon.js"></script> -->
	<script async defer src="//client.perimeterx.net/PXTU0h5FRQ/main.min.js"></script>
</svelte:head>

<slot />

<Modals>
	<div
		slot="backdrop"
		class="backdrop cursor-default"
		class:dark-mode={$app.global.loadingDarkMode}
		class:z-102={$app.global.loading}
		role="button"
		aria-pressed="false"
		tabindex="0"
		on:click={() => window.dispatchEvent(new CustomEvent('global:backdrop-click'))}
		on:keydown={() => window.dispatchEvent(new CustomEvent('global:backdrop-click'))}
	/>
</Modals>
