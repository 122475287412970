import { browser } from '$app/environment';
import { qualifyExperiments } from '$lib/services/experiments';

/** @type {import('./$types').LayoutLoad} */
export async function load() {
	if (browser) {
		await qualifyExperiments();
	}
	return {};
}

export const prerender = true;
export const trailingSlash = 'always';
