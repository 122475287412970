<script>
	import loadingSymbol from '$lib/assets/images/shared/loading-v1-x3.gif';
	import ModalAbcmouse from '$lib/components/ModalAbcmouse.svelte';
</script>

<ModalAbcmouse hideHeader hideModalClose>
	<div slot="modal-content" class="flex items-center justify-center">
		<img src={loadingSymbol} alt="loading content" class="h-auto w-[10rem]" />
	</div>
</ModalAbcmouse>
