import { goto } from '$app/navigation';
import { PAGES } from '$lib/utils/constants';
import { redirect } from '@sveltejs/kit';
import { base } from '$app/paths';
import { z } from 'zod';
import * as Sentry from '@sentry/browser';

// TODO: move types to a separate file.

/**
 * A loader function that returns a promise resolving to a module.
 * @typedef {Function} ModuleLoaderFunction
 * @returns {Promise<*>} Promise resolving to the imported module.
 */

/**
 * The result of import.meta.glob which maps each module file path to a loader function.
 * @typedef {{[key: string]: ModuleLoaderFunction}} GlobResult
 */

/**
 * @typedef {{[key: string]: () => Promise<boolean>}} AuthorizerPolicy
 * An async function that takes no arguments and returns a boolean.
 */

/**
 * @typedef {{[key: string]: () => Promise<string>}} AuthorizerPolicyExceptionHandler
 * An async function that returns a string.
 */

/**
 * @typedef {object} AuthorizerProps
 * @property {string} routeId
 * @property {GlobResult} modules
 */

const SchemaGlobResult = z.record(z.function().returns(z.promise(z.any())));

const SchemaAuthorizerProps = z.object({
	modules: SchemaGlobResult,
	routeId: z.string()
});

export default class Authorizer {
	/**
	 * Creates an instance of Authorizer.
	 * @param {AuthorizerProps} props - properties
	 * @memberof Authorizer
	 */
	constructor(props) {
		this.propsSchema = SchemaAuthorizerProps;
		this.validate(props);
		this.policies = props.modules;
		this.routeId = props.routeId;
	}

	/**
	 *
	 *
	 * @param {AuthorizerProps} props - properties
	 * @returns {boolean}
	 * @memberof Authorizer
	 */
	validate(props) {
		const validation = this.propsSchema.safeParse(props);
		if (!validation.success) {
			if (import.meta.env.DEV) {
				console.error(`Invalid input: ${validation.error.message}`);
			} else {
				Sentry.addBreadcrumb({
					category: 'authorizer-validate',
					message: `Invalid input: ${validation.error.message}`,
					level: 'info'
				});
			}
			return false;
		}
		return true;
	}

	/**
	 *
	 * @returns {Promise<{ policies: object; policiesExceptionHandler: AuthorizerPolicyExceptionHandler; }>}
	 * @memberof Authorizer
	 */
	async loadPolicies() {
		/** @type {AuthorizerPolicy} */
		const policies = {};
		/** @type {AuthorizerPolicyExceptionHandler} */
		const policiesExceptionHandler = {};
		await Promise.all(
			Object.entries(this.policies).map(async ([filename, loader]) => {
				const policyCurrentFile = await loader();
				if (!policyCurrentFile || !policyCurrentFile.policy) return;
				const route = filename.match(/\/(.+?)\/-policy/)?.[1];
				const routeId = `/${route !== undefined ? route : ''}`;
				policies[routeId] = policyCurrentFile.policy;
				policiesExceptionHandler[routeId] =
					policyCurrentFile.policyExceptionHandler ?? Authorizer.defaultPolicyAuthorizer;
			})
		);
		return { policies, policiesExceptionHandler };
	}

	/**
	 * Authorizes the current route based on policies.
	 * // TODO: consider moving loader outside
	 * @memberof Authorizer
	 * @returns {Promise<string|void>}
	 */
	async browserAuthorize() {
		const { policies, policiesExceptionHandler } = await this.loadPolicies();
		const policyCurrentRoute = Object.keys(policies).find((id) => this.routeId.startsWith(id));

		if (!policyCurrentRoute) return;

		// @ts-ignore // TODO: to fix later
		const isActionAllow = await policies[policyCurrentRoute]();

		if (!isActionAllow) {
			let pathToRedirect = await policiesExceptionHandler[policyCurrentRoute]();
			const currentPath = base ? base + this.routeId : this.routeId;

			if (!pathToRedirect || currentPath === pathToRedirect) {
				console.error(
					`Path undefined or Circular reference found to ${pathToRedirect} from ${currentPath}.`
				);
				pathToRedirect = await Authorizer.defaultPolicyAuthorizer();
			}

			Sentry.addBreadcrumb({
				category: 'navigation',
				message: `Authorizer redirecting to ${pathToRedirect}`,
				level: 'info'
			});
			await Authorizer.redirect(pathToRedirect);
		}
	}

	static async defaultPolicyAuthorizer() {
		// TODO: create a functionality to force a transition without a policy check.
		return PAGES.INTERNAL.HOME;
	}

	/**
	 *
	 * @static
	 * @param {string} url - target url of the destination
	 * @returns {Promise<void>}
	 * @memberof Authorizer
	 */
	static async redirect(url) {
		if (typeof window === 'undefined') {
			throw redirect(301, url);
		}
		if (url.startsWith('http')) {
			return new Promise((resolve) => {
				window.location.href = url;
				resolve();
			});
		} else {
			return await goto(url);
		}
	}
}
